import * as React from 'react';
import {useState, useEffect} from 'react';
import axios from 'axios';
import SButton from '../SButton';
import Menubar from './Menubar';
import './SlotPage.css'; // Optional CSS file for styling
import imgIsld1 from '../../../src/assets/images/Islands/1.webp'
import imgIsld2 from '../../../src/assets/images/Islands/2.webp'
import imgIsld3 from '../../../src/assets/images/Islands/3.webp'
import imgIsld4 from '../../../src/assets/images/Islands/4.webp'
import imgIsld5 from '../../../src/assets/images/Islands/5.webp'
import imgIsld6 from '../../../src/assets/images/Islands/6.webp'
import imgIsld7 from '../../../src/assets/images/Islands/7.webp'
import imgIsld8 from '../../../src/assets/images/Islands/8.webp'
import imgIsld9 from '../../../src/assets/images/Islands/9.webp'
import imgIsld10 from '../../../src/assets/images/Islands/10.webp'
import imgIsld11 from '../../../src/assets/images/Islands/11.webp'
import imgIsld12 from '../../../src/assets/images/Islands/12.webp'
import slotImg from '../../../src/assets/images/icons/slot.webp'
import fruitImg1 from '../../../src/assets/images/icons/fruitImg1.webp'
import fruitImg2 from '../../../src/assets/images/icons/fruitImg2.webp'
import fruitImg3 from '../../../src/assets/images/icons/fruitImg3.webp'
import fruitImg4 from '../../../src/assets/images/icons/fruitImg4.webp'
import fruitImg5 from '../../../src/assets/images/icons/fruitImg5.webp'
import fruitImg6 from '../../../src/assets/images/icons/fruitImg6.webp'

const island_image= [ imgIsld1,  imgIsld2, imgIsld3,imgIsld4, imgIsld5, imgIsld6, imgIsld7, imgIsld8, imgIsld9, imgIsld10, imgIsld11, imgIsld12,];
const fruit_image= [fruitImg1, fruitImg2, fruitImg3, fruitImg4, fruitImg5, fruitImg6 ];
const apiUrl = process.env.REACT_APP_API_BASE_URL;
const SlotPage = ({telegramId, func}) => {
    const [user, setUser] = useState(null);    
    const [error, setError] = useState('');
    const [spin, setSpin] = useState(false)
    const [ring1, setRing1] = useState(null)
    const [ring2, setRing2] = useState(null)
    const [ring3, setRing3] = useState(null)
    const [price, setPrice] = useState(0)
    const [input, setInput] = useState(null)
    const [realBet, setRealBet] = useState(null)
    const [jackpot, setJackpot] = useState(0)
    const [balance, setBalance] = useState(100000)

    /*---------Fetch User Information---------------*/
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/user/${telegramId}`);
                setUser(response.data);
            } catch (err) {
                setError(err.response?.status === 404 ? 'User not found' : 'Error fetching user');
            }
        };
        fetchUser();
    }, [telegramId]);

    useEffect(() => {
        setPrice(100);
        win()
    }, [ring3])
    if (error) {
        return <div>{error}</div>;
    }


    if (!user) {
        return <div>Loading...</div>;
    }
const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
};
 function row1() {

    if (!spin) {
    return (
        <>
            {shuffleArray(fruit_image).map((img, index) => (
                <div key={index} className="ringEnd">
                <img src={img} className="fruit_1" />
                </div>
            ))}
        </>
            )
    } else if (spin && ring1 == undefined) {
    return (
        <>
        <div className="ringMoving"><img src={fruitImg4} className="fruit_1" /></div>
        <div className="ringMoving"><img src={fruitImg3} className="fruit_1" /></div>
        <div className="ringMoving"><img src={fruitImg1} className="fruit_1" /></div>
        <div className="ringMoving"><img src={fruitImg5} className="fruit_1" /></div>
        <div className="ringMoving"><img src={fruitImg6} className="fruit_1" /></div>
        <div className="ringMoving"><img src={fruitImg2} className="fruit_1" /></div>
        </>
            )
    } else if (ring1 >= 1 && ring1 <= 50 ) {
    return (
        <>
        <div className="ringEnd"><img src={fruitImg1} className="fruit_1" /></div>
        <div className="ringEnd"><img src={fruitImg2} className="fruit_1" /></div>
        <div className="ringEnd"><img src={fruitImg4} className="fruit_1" /></div>
        <div className="ringEnd"><img src={fruitImg3} className="fruit_1" /></div>
        <div className="ringEnd"><img src={fruitImg5} className="fruit_1" /></div>
        <div className="ringEnd"><img src={fruitImg6} className="fruit_1" /></div>
        </>
            )  
    } else if (ring1 > 50 && ring1 <= 75) {
        return (
            <>
            <div className="ringEnd"><img src={fruitImg6} className="fruit_1" /></div>
            <div className="ringEnd"><img src={fruitImg5} className="fruit_1" /></div>
            <div className="ringEnd"><img src={fruitImg2} className="fruit_1" /></div>
            <div className="ringEnd"><img src={fruitImg4} className="fruit_1" /></div>
            <div className="ringEnd"><img src={fruitImg3} className="fruit_1" /></div>
            <div className="ringEnd"><img src={fruitImg1} className="fruit_1" /></div>
            </>
                )  
        } else if (ring1 > 75 && ring1 <= 95) {
            return (
                <>
                <div className="ringEnd"><img src={fruitImg3} className="fruit_1" /></div>
                <div className="ringEnd"><img src={fruitImg1} className="fruit_1" /></div>
                <div className="ringEnd"><img src={fruitImg5} className="fruit_1" /></div>
                <div className="ringEnd"><img src={fruitImg6} className="fruit_1" /></div>
                <div className="ringEnd"><img src={fruitImg4} className="fruit_1" /></div>
                <div className="ringEnd"><img src={fruitImg2} className="fruit_1" /></div>
                </>
                    )  
            } else if (ring1 > 95 && ring1 <= 100) {
                return (
                    <>
                    <div className="ringEnd"><img src={fruitImg3} className="fruit_1" /></div>
                    <div className="ringEnd"><img src={fruitImg1} className="fruit_1" /></div>
                    <div className="ringEnd"><img src={fruitImg2} className="fruit_1" /></div>
                    <div className="ringEnd"><img src={fruitImg5} className="fruit_1" /></div>
                    <div className="ringEnd"><img src={fruitImg6} className="fruit_1" /></div>
                    <div className="ringEnd"><img src={fruitImg4} className="fruit_1" /></div>
                    </>
                        )  
                }
 }
 function row2() {

    if (!spin) {
    return (
        <>
            {shuffleArray(fruit_image).map((img, index) => (
                <div key={index} className="ringEnd">
                <img src={img} className="fruit_1" />
                </div>
            ))}
        </>
            )
    } else if (spin && ring2 == undefined) {
    return (
        <>
        <div className="ringMoving"><img src={fruitImg1} className="fruit_1" /></div>
        <div className="ringMoving"><img src={fruitImg2} className="fruit_1" /></div>
        <div className="ringMoving"><img src={fruitImg6} className="fruit_1" /></div>
        <div className="ringMoving"><img src={fruitImg4} className="fruit_1" /></div>
        <div className="ringMoving"><img src={fruitImg5} className="fruit_1" /></div>
        <div className="ringMoving"><img src={fruitImg3} className="fruit_1" /></div>
        </>
            )
    } else if (ring2 >= 1 && ring2 <= 50 ) {
        return (
            <>
            <div className="ringEnd"><img src={fruitImg1} className="fruit_1" /></div>
            <div className="ringEnd"><img src={fruitImg2} className="fruit_1" /></div>
            <div className="ringEnd"><img src={fruitImg5} className="fruit_1" /></div>
            <div className="ringEnd"><img src={fruitImg6} className="fruit_1" /></div>
            <div className="ringEnd"><img src={fruitImg4} className="fruit_1" /></div>
            <div className="ringEnd"><img src={fruitImg3} className="fruit_1" /></div>
            </>
                )  
        } else if (ring2 > 50 && ring2 <= 75) {
            return (
                <>
                <div className="ringEnd"><img src={fruitImg2} className="fruit_1" /></div>
                <div className="ringEnd"><img src={fruitImg4} className="fruit_1" /></div>
                <div className="ringEnd"><img src={fruitImg6} className="fruit_1" /></div>
                <div className="ringEnd"><img src={fruitImg5} className="fruit_1" /></div>
                <div className="ringEnd"><img src={fruitImg3} className="fruit_1" /></div>
                <div className="ringEnd"><img src={fruitImg1} className="fruit_1" /></div>
                </>
                    )  
            } else if (ring2 > 75 && ring2 <= 95) {
                return (
                    <>
                    <div className="ringEnd"><img src={fruitImg5} className="fruit_1" /></div>
                    <div className="ringEnd"><img src={fruitImg6} className="fruit_1" /></div>
                    <div className="ringEnd"><img src={fruitImg4} className="fruit_1" /></div>
                    <div className="ringEnd"><img src={fruitImg3} className="fruit_1" /></div>
                    <div className="ringEnd"><img src={fruitImg1} className="fruit_1" /></div>
                    <div className="ringEnd"><img src={fruitImg2} className="fruit_1" /></div>
                    </>
                        )  
                } else if (ring2 > 95 && ring2 <= 100) {
                    return (
                        <>
                        <div className="ringEnd"><img src={fruitImg3} className="fruit_1" /></div>
                        <div className="ringEnd"><img src={fruitImg4} className="fruit_1" /></div>
                        <div className="ringEnd"><img src={fruitImg1} className="fruit_1" /></div>
                        <div className="ringEnd"><img src={fruitImg2} className="fruit_1" /></div>
                        <div className="ringEnd"><img src={fruitImg5} className="fruit_1" /></div>
                        <div className="ringEnd"><img src={fruitImg6} className="fruit_1" /></div>
                        </>
                            )  
                    }
 }
 function row3() {

    if (!spin) {
    return (
        <>
        {shuffleArray(fruit_image).map((img, index) => (
            <div key={index} className="ringEnd">
            <img src={img} className="fruit_1" />
            </div>
        ))}
    </>
            )
    } else if (spin && ring3 == undefined) {
    return (
        <>
        <div className="ringMoving"><img src={fruitImg1} className="fruit_1" /></div>
        <div className="ringMoving"><img src={fruitImg2} className="fruit_1" /></div>
        <div className="ringMoving"><img src={fruitImg4} className="fruit_1" /></div>
        <div className="ringMoving"><img src={fruitImg5} className="fruit_1" /></div>
        <div className="ringMoving"><img src={fruitImg6} className="fruit_1" /></div>
        <div className="ringMoving"><img src={fruitImg3} className="fruit_1" /></div>
        </>
            )
    } else if (ring3 >= 1 && ring3 <= 50 ) {
        return (
            <>
            {shuffleArray(fruit_image).map((img, index) => (
                <div key={index} className="ringEnd">
                <img src={img} className="fruit_1" />
                </div>
            ))}
        </>
                )  
        } else if (ring3 > 50 && ring3 <= 75) {
            return (
                <>
        {shuffleArray(fruit_image).map((img, index) => (
            <div key={index} className="ringEnd">
            <img src={img} className="fruit_1" />
            </div>
        ))}
    </>
                    )  
            } else if (ring3 > 75 && ring3 <= 95) {
                return (
                    <>
        {shuffleArray(fruit_image).map((img, index) => (
            <div key={index} className="ringEnd">
            <img src={img} className="fruit_1" />
            </div>
        ))}
    </>
                        )  
                } else if (ring3 > 95 && ring3 <= 100) {
                    return (
                        <>
                        {shuffleArray(fruit_image).map((img, index) => (
                            <div key={index} className="ringEnd">
                            <img src={img} className="fruit_1" />
                            </div>
                        ))}
                    </>
                            )  
                    }
     }

 function rand() {
      setRing1(Math.floor(Math.random() * (100 - 1) + 1))
      setTimeout(function(){setRing2(Math.floor(Math.random() * (100 - 1) + 1))}, 1000)
      setTimeout(function(){setRing3(Math.floor(Math.random() * (100 - 1) + 1))}, 2000)
    }

function play() {
    func(-1, user, 'energy' );
    if (ring3 > 1 || !spin){
    if(true){
    // if (input <= balance && input >= 1){
    if(true){
    setRealBet(input)
    setSpin(true)
    setRing1()
    setRing2()
    setRing3()
    setBalance(balance - input)
    setJackpot(jackpot + (input / 2))
    setTimeout(function(){
   rand()
    }, 2000)
} else {
    setPrice(10)
}

}
}}
function win() {
    if (ring1 <= 50 && ring2 <= 50 && ring3 <= 50 && ring1 != undefined) {
        setPrice(100);
    } else if (ring1 > 50 && ring1 <= 75 && ring2 > 50 && ring2 <= 75 && ring3 > 50 && ring3 <= 75 && ring1 != undefined) {
        setPrice(10000)
    } else if (ring1 > 75 && ring1 <= 95 && ring2 > 75 && ring2 <= 95 && ring3 > 75 && ring3 <= 95 && ring1 != undefined) {
        setPrice(10000)
    } else if (ring1 > 95 && ring1 <= 100 && ring2 > 95 && ring2 <= 100 && ring3 > 95 && ring3 <= 100 && ring1 != undefined) {
        setPrice(10000)
    } else {
        setPrice(10000)
    } 
    setPrice(500);
    func()
    func(price, user, 'coin' );
}

  return (
    <>

    <div class="image-container"> 
        <div>
            <img src={island_image[user.island_level]} alt="kk" className="island layer1" />
        </div>



<div className="slog_img1">
    <div className="slot_img2">
      <img src={slotImg} alt="Background" className="slot_img3" />
    </div>
    <div className="fullSlot">
        <div className="slot">
            <div className="row">
                {row1()}
            </div>
            <div className="row">
                {row2()}
            </div>
            <div className="row">
                {row3()}
            </div>
        </div>
    </div>
</div>
    <div className="image4 layer4">
            <div>
            <SButton label="SPIN" onClick={() => play()}/>
            </div> 
        </div>
    </div>
</>
  );
};
export default SlotPage;
